// src/components/Card.tsx
import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import StakingKeyValueSet from "./StakingKeyValueSet";
import TokenIcon from "./TokenIcon";
import { mode } from "@chakra-ui/theme-tools";
import { GradientBox } from "./GradientBox";

interface ICardProps {
  isMaintenance?: boolean;
  isEvent?: boolean;
  isClosed?: boolean;
  isGovernance?: boolean;
  isMembership?: boolean;
  isAppOnly?: boolean;
  isLockup?: boolean;
  isComingSoon?: boolean;
  symbol?: string;
  img?: string[];
  title?: string;
  subtitle?: string;
  list?: {
    title: React.ReactNode;
    value: React.ReactNode;
    tooltip?: string;
    invisible?: boolean;
  }[];
  onClick: () => void;
  buttonTitle?: string;
}

const Card = (props: ICardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);
  const COLOR_BG_PRIMARY = useColorModeValue("#FFFFFF", "#1A1D28");
  return (
    <GradientBox>
      <Box
        position="relative"
        zIndex={1}
        textAlign="center"
        w="100%"
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        minHeight={"350px"}
        minW={"285px"}
        padding={{ base: "20px", md: "40px 16px 16px 16px" }}
      >
        {/* Badge */}
        {/* {props.isMaintenance && (
          <MaintenanceBadge>{t("card.badge.maintenance")}</MaintenanceBadge>
        )}
        {props.isEvent && (
          <EventBadge>{t("staking.list.eventBadge")}</EventBadge>
        )}
        {props.isClosed && <CloseBadge>{t("지원 종료")}</CloseBadge>}
        {props.isGovernance && (
          <Tooltip
            label={t("staking.list.votingPowerBadgeTooltip1", {
              symbol: props.symbol,
              chainname: t(props.network ?? ""),
              chain: props.network,
            })}
            aria-label="Governance Tooltip"
          >
            <Box position="absolute" top={4} right={4}>
              <GovernanaceBadge />
            </Box>
          </Tooltip>
        )} */}

        {/* Token */}
        <VStack spacing={0} w={"100%"}>
          <TokenIcon img={props.img} margin={"0px -4px"} />
          <Text as="h5" fontSize="24px" fontWeight="bold" whiteSpace={"nowrap"}>
            {props.title}
          </Text>
          <Text as="h6" fontSize="14px" color={"gray.500"}>
            {props.subtitle}
          </Text>
        </VStack>

        {/* KeyValue */}
        <VStack spacing={"8px"} mt={6} width={"100%"}>
          {/* {props.isMembership && <MembershipBadge />} */}
          {props.list?.map(
            ({ title, value, tooltip, invisible }) =>
              !invisible && (
                <StakingKeyValueSet
                  key={title?.toString()}
                  keyName={title}
                  questionTooltip={tooltip}
                  keyFontStyles={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "22px",
                    letterSpacing: "0.07px",
                  }}
                  valueFontStyles={{
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "22px",
                    letterSpacing: "0.07px",
                  }}
                >
                  {value}
                </StakingKeyValueSet>
              )
          )}
        </VStack>

        {/* Button */}
        <Button
          fontSize="15px"
          fontWeight="500"
          lineHeight="20px"
          p="10px 12px"
          bg={"purple.300"}
          onClick={props.onClick}
          w={"100%"}
          onMouseEnter={handleMouseEnter}
          color={COLOR_BG_PRIMARY}
          textStyle={"label3"}
          mt={"auto"}
        >
          {props.buttonTitle ?? " Stake Now"}
        </Button>
      </Box>
    </GradientBox>
  );
};

// const MaintenanceBadge = (props) => (
//   <Badge position="absolute" top={0} left={0} colorScheme="black" {...props} />
// );

// const EventBadge = (props) => (
//   <Badge position="absolute" top={0} left={0} colorScheme="blue" {...props} />
// );

// const CloseBadge = (props) => (
//   <Badge position="absolute" top={0} left={0} colorScheme="black" {...props} />
// );

export default Card;
