import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import KlayImg from "../assets/img/icons/network_Klaytn.svg";
import EthereumImg from "../assets/img/icons/network_Ethereum.svg";
import FinschiaImg from "../assets/img/icons/network_Finschia.svg";
import CosmosImg from "../assets/img/icons/network_Cosmos.svg";
import PolygonImg from "../assets/img/icons/network_Polygon.svg";

type TokenInfo = {
  fullName: string;
  image: string;
};

type TokenInfoMap = {
  [key: string]: TokenInfo;
};

// Combined map of token symbols to their full names and images
export const TOKEN_INFO_MAP: TokenInfoMap = {
  ETH: { fullName: "Ethereum", image: EthereumImg },
  FNSA: { fullName: "Finschia", image: FinschiaImg },
  TFNSA: { fullName: "Finschia", image: FinschiaImg },
  KLAY: { fullName: "Klaytn", image: KlayImg },
  ATOM: { fullName: "Cosmos", image: CosmosImg },
  // Add more token mappings as needed
};

interface TokenLabelProps {
  tokenSymbol: string;
}

// Component to render the token symbol with full name and image
const TokenLabel: React.FC<TokenLabelProps> = ({ tokenSymbol }) => {
  const tokenInfo = TOKEN_INFO_MAP[tokenSymbol] || {
    fullName: tokenSymbol,
    image: "",
  }; // Default to symbol if info not found

  return (
    <Flex dir="row" gap="4px" alignItems="center">
      {tokenInfo.image && (
        <img
          src={tokenInfo.image}
          alt={`${tokenInfo.fullName} icon`}
          width="16px"
          height="16px"
        />
      )}
      <Text>{tokenInfo.fullName}</Text>
    </Flex>
  );
};

export default TokenLabel;
