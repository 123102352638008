import {
  Box,
  Divider,
  Flex,
  Text,
  Spinner,
  Button,
  IconButton,
  useColorMode,
  useColorModeValue,
  Link,
  Icon,
} from "@chakra-ui/react";
import useFetch from "./hooks/useFetch";
import { InfoStatusResponse, ProductResponse } from "./types";
import CardSection from "./components/CardSection";
import { GradientButton } from "./components/GradientButton";
import discord from "./assets/img/icons/logo_discord.svg";
import medium from "./assets/img/icons/logo_medium.svg";
import telegram from "./assets/img/icons/logo_telegram.svg";
import x from "./assets/img/icons/logo_x.svg";
import { mode } from "@chakra-ui/theme-tools";
import { API_URL, NEOPIN_DAPP_LINK } from "./config/constants/common";
import { GradientBox } from "./components/GradientBox";
import { useMemo } from "react";
import BigNumber from "bignumber.js";

const BENEFIT = [
  {
    title: "Pool",
    content:
      "Provide liquidity directly to the pool of your choice and receive NPT in addition to the base rewards.",
    link: "/pool",
    buttonText: "Deposit Now",
  },
  {
    title: "Swap",
    content:
      "Connect your wallet and simply swap any tokens supported by NEOPIN in seconds",
    link: "/swap",
    buttonText: "Swap Now",
  },
  {
    title: "Bridge",
    content:
      "NEOPIN Bridge enables the transfer of certain digital assets back and forth between Ethereum, Polygon and Kaia(Klaytn).",
    link: "/bridge",
    buttonText: "Bridge Now",
  },
];
const H1FontStyle = {
  fontFamily: "Cal Sans",
  fontSize: { base: "36px", md: "112px" },
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: { base: "40px", md: "116px" },
  letterSpacing: { base: "1.44px", md: "4.48px" },
};
const Home = () => {
  const { data, error, isLoading } = useFetch<ProductResponse>(
    API_URL + "/v4/product"
  );
  const { colorMode } = useColorMode();
  const { data: tvlStatus } = useFetch<InfoStatusResponse>(
    API_URL + "/v2/info/stats"
  );
  // if (isLoading) return <Spinner />;
  const textColor = useColorModeValue("#000", "#FFF");
  const reverseTextColor = useColorModeValue("#FFF", "#1A1D28");
  const titleTextColor = useColorModeValue("neutral.1200", "gray.0");
  const tvl = useMemo(() => {
    let tvl: string = "";
    tvl = tvlStatus?.info?.totalTvl ?? "";
    if (tvl) {
      const bignumberTvl = new BigNumber(tvl)
        .div("1000000")
        .toFixed(0, BigNumber.ROUND_DOWN);
      return `$${bignumberTvl}M`;
    }
    return "";
  }, [tvlStatus]);
  return (
    <Box
      w={{ base: "100%", md: "1200px" }}
      m={"0 auto"}
      p={{ base: "20px", md: "0px" }}
    >
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        py={{ base: "30px", md: "120px" }}
        gap={"36px"}
      >
        <Box w="100%" textAlign="center" mt={"100px"} {...H1FontStyle}>
          <Text
            as="h1"
            bgGradient="linear(to-br, #82C0FD, #6653DD)"
            bgClip="text"
          >
            DEFI,
          </Text>
          <Text as="h1">SIMPLIFIED.</Text>
          <Text as="h1">ONLY ON-CHAIN.</Text>
        </Box>
        <Text
          textStyle={{ base: "body14R", md: "body20R" }}
          color={"gray.500"}
          textAlign={"center"}
        >
          A one-stop non-custodial Permissioned DeFi protocol for utilizing your
          crypto securely.
          <br /> NEOPIN provides crypto products while taking advantage of both
          CeFi and DeFi.
        </Text>
      </Flex>
      <Flex
        gap="20px"
        width="100%"
        justifyContent="center"
        py="20px"
        my={"80px"}
      >
        <Box display="flex" flexDirection="column" alignItems="center" flex="1">
          <Text
            textStyle={{ base: "display32R", md: "display48" }}
            textAlign={"center"}
          >
            59
          </Text>
          <Text
            textStyle={{ base: "caption12R", md: "caption20R" }}
            color={"gray.400"}
            textAlign={"center"}
          >
            Supported Token
          </Text>
        </Box>
        <Divider orientation="vertical" height="64px" borderColor="gray.200" />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          flex="1"
          textAlign={"center"}
        >
          <Text textStyle={{ base: "display32R", md: "display48" }}>{tvl}</Text>
          <Text
            textStyle={{ base: "caption12R", md: "caption20R" }}
            color={"gray.400"}
          >
            TVL
          </Text>
        </Box>
        <Divider orientation="vertical" height="64px" borderColor="gray.200" />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          flex="1"
          textAlign={"center"}
        >
          <Text textStyle={{ base: "display32R", md: "display48" }}>22</Text>
          <Text
            textStyle={{ base: "caption12R", md: "caption20R" }}
            color={"gray.400"}
          >
            Nodes In Operation
          </Text>
        </Box>
      </Flex>

      <Flex
        gap="20px"
        width="100%"
        flexDirection={"column"}
        justifyContent="center"
        py="20px"
      >
        <CardSection
          title="Staking"
          data={data?.staking}
          type="staking"
          link="/staking"
          subTitle="Decentralized and secure way to access the best staking experience"
        />
        <CardSection
          title="Liquid Staking"
          data={data?.liquidStaking}
          type="liquidStaking"
          link="/staking"
          subTitle="Stake tokens to get rewards and receive liquid tokens. Use liquid tokens throughout DeFi to get more rewards"
        />
        <CardSection
          title="Derivatives"
          data={data?.derivative}
          type="derivative"
          link="/derivatives"
          subTitle="Derivatives maximize reward rates by utilizing multiple DeFi protocols at once. Various DeFi protocols, such as Staking, Lending, RWA, Distributed Asset Management, and more, are strategically linked via NEOPIN smart contracts for optimal returns."
        />
        {/* <CardSection
          title="Index"
          data={data?.index}
          type="index"
          link="/index"
          subTitle="Diversify your portfolio with NEOPIN index product, a TradFi ETF like product in decentralized measure"
        /> */}
      </Flex>
      <Flex direction={"column"} gap={{ base: "auto", md: "40px" }}>
        <Box
          textAlign="left"
          py={8}
          fontFamily="'Cal Sans', sans-serif"
          fontSize={{ base: "32px", md: "72px" }}
          fontStyle="normal"
          fontWeight={600}
          lineHeight={{ base: "40px", md: "76px" }} // 105.556%
          letterSpacing={{ base: "1.28px", md: "2.88px" }}
        >
          <Text color={titleTextColor} textTransform="uppercase">
            DeFi Meets
          </Text>
          <Text
            bgGradient="radial-gradient(circle, #2CC7FB 0%, #B66CFF 100%)"
            bgClip="text"
            textTransform="uppercase"
          >
            Safe and Secure Environment
          </Text>
        </Box>
        <Box
          display={"flex"}
          flexDirection={{ base: "column", md: "row" }}
          gap={"21px"}
        >
          {BENEFIT.map(({ title, content, buttonText, link }) => {
            return (
              <GradientBox>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                  minHeight={{ base: "180px ", md: "320px" }}
                  p={{ base: "16px", md: "32px" }}
                  justifyContent={"space-between"}
                >
                  <Flex direction={"column"} gap={"4px"}>
                    <Text
                      color={titleTextColor}
                      textStyle={{ base: "title20SB", md: "title28SB" }}
                    >
                      {title}
                    </Text>
                    <Text
                      color={"gray.500"}
                      textStyle={{ base: "body14R", md: "body18R" }}
                    >
                      {content}
                    </Text>
                  </Flex>
                  <GradientButton
                    link={NEOPIN_DAPP_LINK + link}
                    color={titleTextColor}
                  >
                    {buttonText}
                  </GradientButton>
                </Box>
              </GradientBox>
            );
          })}
        </Box>
      </Flex>
      <Box width="100%" p="48px 0 ">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          maxWidth="1200px"
          mx="auto"
          direction={{ base: "column", md: "row" }}
          gap={{ base: "8px", md: "auto" }}
        >
          <Text
            color={titleTextColor}
            fontFamily="'Cal Sans', sans-serif"
            fontSize={{ base: "24px", md: "48px" }}
            fontStyle="normal"
            fontWeight={600}
            lineHeight={{ base: "24px", md: "60px" }}
            letterSpacing="1.92px"
            textTransform="uppercase"
          >
            Join Community
          </Text>
          <Flex flexDirection="row" gap="14px">
            <Link
              href="https://discord.gg/neopinofficial"
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              w={{ base: "32px", md: "48px" }}
              h={{ base: "32px", md: "48px" }}
              borderRadius="50%"
            >
              <Icon viewBox="0 0 20 20" fill="none" boxSize={"80%"}>
                <path
                  d="M16.3483 4.44109C15.1871 3.92456 13.9299 3.54966 12.6204 3.33305C12.6089 3.3327 12.5975 3.33475 12.5869 3.33907C12.5764 3.34339 12.5669 3.34986 12.5593 3.35804C12.4021 3.63297 12.2188 3.99121 12.0965 4.26614C10.7075 4.06619 9.29496 4.06619 7.90596 4.26614C7.78373 3.98288 7.60039 3.63297 7.43452 3.35804C7.42579 3.34138 7.39959 3.33305 7.3734 3.33305C6.06384 3.54966 4.8154 3.92456 3.64552 4.44109C3.63679 4.44109 3.62806 4.44942 3.61933 4.45775C1.24466 7.84853 0.589884 11.1477 0.912908 14.4135C0.912908 14.4301 0.921639 14.4468 0.939099 14.4551C2.51057 15.5548 4.02093 16.2213 5.51383 16.6629C5.54002 16.6712 5.56621 16.6629 5.57494 16.6462C5.92416 16.188 6.23845 15.7048 6.50909 15.1966C6.52655 15.1633 6.50909 15.13 6.47417 15.1216C5.97654 14.9383 5.5051 14.7217 5.04239 14.4718C5.00746 14.4551 5.00746 14.4051 5.03366 14.3802C5.12969 14.3135 5.22572 14.2385 5.32176 14.1719C5.33922 14.1552 5.36541 14.1552 5.38287 14.1635C8.38613 15.4715 11.6251 15.4715 14.5934 14.1635C14.6109 14.1552 14.6371 14.1552 14.6546 14.1719C14.7506 14.2469 14.8466 14.3135 14.9427 14.3885C14.9776 14.4135 14.9776 14.4635 14.9339 14.4801C14.4799 14.7384 13.9998 14.9467 13.5021 15.13C13.4672 15.1383 13.4585 15.1799 13.4672 15.2049C13.7466 15.7131 14.0609 16.1963 14.4014 16.6546C14.4276 16.6629 14.4538 16.6712 14.4799 16.6629C15.9816 16.2213 17.4919 15.5548 19.0634 14.4551C19.0809 14.4468 19.0896 14.4301 19.0896 14.4135C19.4737 10.6395 18.4523 7.36532 16.3832 4.45775C16.3744 4.44942 16.3657 4.44109 16.3483 4.44109ZM6.96307 12.4223C6.06384 12.4223 5.31303 11.6309 5.31303 10.6561C5.31303 9.68138 6.04638 8.88992 6.96307 8.88992C7.8885 8.88992 8.62185 9.68971 8.61312 10.6561C8.61312 11.6309 7.87977 12.4223 6.96307 12.4223ZM13.0482 12.4223C12.1489 12.4223 11.3981 11.6309 11.3981 10.6561C11.3981 9.68138 12.1315 8.88992 13.0482 8.88992C13.9736 8.88992 14.7069 9.68971 14.6982 10.6561C14.6982 11.6309 13.9736 12.4223 13.0482 12.4223Z"
                  fill={textColor}
                />
              </Icon>
            </Link>
            <Link
              href="https://twitter.com/neopinofficial"
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              w={{ base: "32px", md: "48px" }}
              h={{ base: "32px", md: "48px" }}
              borderRadius="50%"
            >
              <Icon viewBox="0 0 20 20" fill="none" boxSize={"80%"}>
                <path
                  d="M14.7899 2.5H17.3457L11.7624 8.85417L18.3307 17.5H13.1874L9.1599 12.2558L4.55073 17.5H1.9924L7.9649 10.7042L1.66406 2.5H6.9374L10.5791 7.29333L14.7899 2.5ZM13.8932 15.9767H15.3099L6.1674 3.94333H4.6474L13.8932 15.9767Z"
                  fill={textColor}
                />
              </Icon>
            </Link>
            <Link
              href="https://medium.com/neopin-official"
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              w={{ base: "32px", md: "48px" }}
              h={{ base: "32px", md: "48px" }}
              borderRadius="50%"
            >
              <Icon viewBox="0 0 20 20" fill="none" boxSize={"80%"}>
                <path
                  d="M6 4.16699C7.5913 4.16699 9.11742 4.78157 10.2426 5.87554C11.3679 6.9695 12 8.45323 12 10.0003C12 11.5474 11.3679 13.0312 10.2426 14.1251C9.11742 15.2191 7.5913 15.8337 6 15.8337C4.4087 15.8337 2.88258 15.2191 1.75736 14.1251C0.632141 13.0312 0 11.5474 0 10.0003C0 8.45323 0.632141 6.9695 1.75736 5.87554C2.88258 4.78157 4.4087 4.16699 6 4.16699ZM15 5.13921C16.5 5.13921 17.5 7.31602 17.5 10.0003C17.5 12.6846 16.5 14.8614 15 14.8614C13.5 14.8614 12.5 12.6846 12.5 10.0003C12.5 7.31602 13.5 5.13921 15 5.13921ZM19 5.62533C19.38 5.62533 19.712 6.42935 19.88 7.80894L19.927 8.23963L19.946 8.4681L19.976 8.94838L19.986 9.20019L19.998 9.72616L20 10.0003L19.998 10.2745L19.986 10.8005L19.976 11.0532L19.946 11.5325L19.926 11.761L19.881 12.1917C19.712 13.5723 19.381 14.3753 19 14.3753C18.62 14.3753 18.288 13.5713 18.12 12.1917L18.073 11.761C18.0661 11.6849 18.0598 11.6087 18.054 11.5325L18.024 11.0523C18.0201 10.9684 18.0168 10.8844 18.014 10.8005L18.002 10.2745V9.72616L18.014 9.20019L18.024 8.94741L18.054 8.4681L18.074 8.23963L18.119 7.80894C18.288 6.42838 18.619 5.62533 19 5.62533Z"
                  fill={textColor}
                />
              </Icon>
            </Link>
            <Link
              href="https://t.me/neopin_eng"
              isExternal
              display="flex"
              justifyContent="center"
              alignItems="center"
              w={{ base: "32px", md: "48px" }}
              h={{ base: "32px", md: "48px" }}
              borderRadius="50%"
            >
              <Icon viewBox="0 0 20 20" fill="none" boxSize={"80%"}>
                <path
                  d="M17.2058 3.01014L2.44229 8.78013C1.43475 9.19028 1.44058 9.75993 2.25744 10.014L6.04781 11.2123L14.8176 5.6044C15.2323 5.34869 15.6112 5.48625 15.2997 5.76643L8.19447 12.2656H8.1928L8.19447 12.2664L7.933 16.2262C8.31604 16.2262 8.48507 16.0481 8.6999 15.838L10.541 14.0235L14.3705 16.8903C15.0766 17.2845 15.5837 17.0819 15.7594 16.2279L18.2733 4.22034C18.5306 3.17471 17.8794 2.70126 17.2058 3.01014Z"
                  fill={textColor}
                />
              </Icon>
            </Link>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Home;
